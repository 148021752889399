import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
} from "mdb-react-ui-kit";
import Link from "next/link";
import { FC, ReactNode, useEffect, useState } from "react";
import styles from "../../../styles/common/InfoModal.module.scss";
import { ModalSize } from "../../../models/common/enums";

const InfoModalContainer: FC<{
    children: ReactNode;
    title: string;
    size?: ModalSize;
    show: boolean;
    setShow: (state: boolean) => void;
    showFooter?: boolean;
}> = ({ children, title, size, show, setShow, showFooter }) => {
    const toggleShow = () => setShow(!show);

    const [isBrowser, setIsBrowser] = useState(false);
    useEffect(() => {
        setIsBrowser(typeof window !== "undefined");
    }, []);

    return isBrowser ? (
        <MDBModal
            show={show}
            setShow={setShow}
            tabIndex="-1"
            className="px-4 px-lg-0"
        >
            <MDBModalDialog
                centered
                size={size === ModalSize.MD ? undefined : size}
            >
                <MDBModalContent>
                    <MDBModalHeader className="position-relative border border-0 d-flex justify-content-center">
                        <MDBModalTitle className="fw-bold">
                            {title}
                        </MDBModalTitle>
                        <MDBBtn
                            className={`${styles.closeButton} btn-close`}
                            color="none"
                            onClick={toggleShow}
                        />
                    </MDBModalHeader>
                    <MDBModalBody className={styles.textBody}>
                        {children}
                    </MDBModalBody>

                    {showFooter && (
                        <MDBModalFooter className="border border-0 d-flex justify-content-center">
                            <p className={styles.textCaption}>
                                {" "}
                                Do you have any other questions? Check out our{" "}
                                <Link href="/faq">FAQ page</Link>.
                            </p>
                        </MDBModalFooter>
                    )}
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : (
        <div />
    );
};

InfoModalContainer.defaultProps = {
    size: ModalSize.MD,
};

export default InfoModalContainer;
