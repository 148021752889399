// eslint-disable-next-line import/no-cycle
import { ReviewReadableTO, SectionsTitleText } from "./common/common";

export interface CardBrochuresContent {
    title: SectionsTitleText[];
    subTitle: SectionsTitleText[];
    imagesrc?: string;
}

export interface CardTikTokContent {
    name: string;
    place: string;
    userImageUrl: string;
    imageCoverUrl: string;
    videoUrl: string;
}

export interface CardStoriesContent {
    title: string;
    imgUrl: string;
    slugId: string;
    readTime: string;
    vendor: string;
    date: string;
    tag?: string;
}

export interface CardVisitContent {
    imageUrl: string;
    title: string;
    place: string;
    region: string;
}

export interface CardReviewContent {
    review: string;
    imgUrl: string;
    user: string;
    work: string;
}

export interface StepsInfo {
    title: SectionsTitleText[];
    subTitle: SectionsTitleText[];
}

interface TravelExperiencesTags {
    id: string;
    value: string;
}

export interface TravelExperiencesContent {
    title: string;
    // eslint-disable-next-line
    icon: any;
    tags: TravelExperiencesTags[];
    onClick: () => void;
}

export interface SmartCardContent {
    index: number;
    image: string;
    title: string;
    description: string;
    onClick: (el: string, index: number) => void;
}

export interface ISmartCard {
    id: string;
    value: SmartCardContent;
}

export interface RadioButtons {
    id: string;
    value: {
        image?: string;
        title: string;
        description?: string;
    };
}

export interface ToursEntity {
    _id: string;
    title?: string;
    price: string;
    startingPrice?: number;
    destinations?: string[] | null;
    image: string;
    duration: number;
    nightDuration: number;
    reviews: number;
    rating: number;
}

interface ToursByEntity {
    title: string;
    body: string;
    image: string;
    tours?: ToursEntity[] | null;
}

export interface ToursByTourTypeEntity extends ToursByEntity {
    tourType: string;
    type: string;
}

export interface ToursByDestinationEntity extends ToursByEntity {
    city: string;
}

export interface DestinationToExploreEntity {
    city: string;
    searchableLabel: string;
    region: string;
    image: string;
    title: string;
    body: string;
    latitude: string;
    longitude: string;
    aggregationId: string;
}
export interface BrochuresEntity {
    type: string;
    description?: string;
    brochure: string;
}

export interface StaticMediaEntity {
    headImage: string;
    previewImageVideo: string;
    stepperImage: string;
    video: string;
}

export interface VideoreviewsEntity {
    _id: string;
    name: string;
    lastname: string;
    city: string;
    avatar: string;
    previewImage: string;
    countryPrefix: string;
    video: string;
}

export interface HomeResponseTO {
    destinationToExplore?: DestinationToExploreEntity[] | null;
    staticMedia: StaticMediaEntity;
    reviews?: ReviewReadableTO[] | null;
}

export enum EBrochuresType {
    ALL,
    ACROSSITALY,
    INTOTHEREGION,
    DOWNTHECITY,
}
